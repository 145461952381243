/* DEMO-SPECIFIC STYLES */
.typing-div {
    display: flex;
    justify-content: start;
}
.typewriter h1 {
    overflow: hidden; /* Ensures the content is not revealed until the animation */
    border-right: .15em solid #4bb2cc; /* The typwriter cursor */
    white-space: nowrap; /* Keeps the content on a single line */
    margin: 0 auto; /* Gives that scrolling effect as the typing happens */
    letter-spacing: .15em; /* Adjust as needed */
    opacity: 0; /* hides the sentence during the delay */
    /* animation will be delayed 1s before start, then will perform within 3.5s and use 25 frames for it. Will repeat 1 time */
    animation:
        typing 3.5s steps(25, end) 1s 1 forwards,
        blink-caret 0.5s step-end infinite,
        hide-caret 0s step-end 5s 1 forwards;
}
  
/* The typing effect */
@keyframes typing {
    from { 
        width: 0;
        opacity: 1;
    }
    to { 
        width: 100%;
        opacity: 1;
    }
}
@keyframes hide-caret {
    from { 
        border-right: .15em solid #4bb2cc; /* The typwriter cursor */
    }
    to { 
        border-right: none;
    }
}
/* The typewriter cursor effect */
@keyframes blink-caret {
    from, to { border-color: transparent }
    50% { border-color: #4bb2cc }
}
  
.zx-app-banner {
    .theme_1_appearing {
        position: absolute;
        bottom: 6px;
        left: 430px;
        width: 170px;
        opacity: 0;
        animation:
            appear-anim 1s steps(10, end) 5s 1 forwards,
      }
      @keyframes appear-anim {
        from { 
            opacity: 0;
        }
        to { 
            opacity: 0.5;
        }
    }    
}
.none-1 {
    display: none;
}