/* Footer */
.zx-footer {
    background-color: $black;
    color: $white;
    overflow: hidden;
    ul.footer-main {
        list-style-type: none;
        padding: 0;
        margin: 0;
        li.zx-footer-link {
            &:hover {
                opacity: 1;
            }
        }
        li.zx-ped {
            &:not(:last-child) {
                margin-bottom: 10px !important;
            }
        }
        li {
            img {
                width: 100px;
            }
            h4 {
                font-size: 18px;
                font-family: $title-font;
                font-weight: bold;
                line-height: 1.4;
                margin-bottom: 20px;
            }
            p {
                font-size: 14px;
                font-weight: 500;
                color: #bbb;
                transition: 0.3s ease-in-out;
                letter-spacing: .02rem;
                line-height: 28px;
            }
            a {
                text-decoration: none;
                color: #bbb;
                font-weight: 500;
                font-size: 14px;
                transition: 0.3s ease-in-out;
                letter-spacing: .02rem;
                line-height: 28px;
                img {
                    margin-top: 20px;
                    width: 30px;
                    padding: 2px;
                    transition: 0.3s ease-in-out;
                    &:hover {
                        transform: scale(1.3);
                    }
                }
            }
            h6 {
                &:nth-child(1) {
                    margin-top: 10px;
                }
                a {
                    color: #bbb;
                    text-decoration: none;
                    display: block;
                    font-weight: 500;
                    font-size: 14px;
                    line-height: 17px;
                    transition: 0.3s ease-in-out;
                    letter-spacing: .02rem;
                    line-height: 28px;
                }
            }
        }
    }

    .bordered {
        border-top: 1px solid #222;
    }

    .last-footer {
        padding: 25px 0;
        display: flex;
        align-items: center;
        justify-content: center;
        p {
            color: #bbb;
            font-size: 15px;
            font-weight: 400;
            text-align: center;
            a {
                text-decoration: none;
                span {
                    color: #7b60e6;
                    font-weight: 500;
                }
            }
        }
    }
}

.subscribe {
    input {
        outline: none;
        border: none;
        font-size: 15px;
        height: 50px;
        border-top-left-radius: 30px;
        border-bottom-left-radius: 30px;
        width: 100%;
        padding: 15px 20px;
        color: $white;
        background-color: $Raisin-Black;
        margin-bottom: 0 !important;
        &::placeholder {
            color: #bbb;
        }
    }
    button {
        background-color: $Pattens-Violet;
        color: $white;
        border: none;
        width: 50px;
        border-top-right-radius: 30px;
        border-bottom-right-radius: 30px;
        height: 50px;
        font-weight: 700;
    }
}
