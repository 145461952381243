//coman veriabal
$title-font: "Quicksand";
$text-font: "Montserrat";
$white: #fff;
$black: #000;
$medium-black: #1b1c20;
$medium-dark: #444444;
$blue: #5c3f6b;
$Pattens-Blue: #dee2e6;
$Pattens-Violet: #6547de;
$gray: #777777;
$text-999: #999;
$guyabano: #f8f8f8;
$yellow: #fcdf15;
$Raisin-Black: #252328;
$Spindle: #bfc3c7;
$Tarawera: #2b3a48;
$cyan: #2ab674;
$LightCoral: #db3a5d;
$red: red;
$light-shade-gray: #ddd;
$light-orange: #ff8a52;

$clash-royale: #6e84ff;

$color-Pink: #ff78aa;
$color-Light-Grey: #6e84ff;
$color-green: #3fa57f;
$color-dark-green: #007a3d;

$gray-light-off: rgba(199, 199, 199, 0.1);

$yellow: rgb(255, 192, 0);
$bg-dark: rgba(0, 0, 0, 0.6);
$white-off: rgba(255, 255, 255, 0.5);
$box-shadow: 0px 0px 15px -5px rgba(0, 0, 0, 0.15);
$white-box-shadow: 0px 0px 50px 0px rgba(255, 255, 255, 0.15);
$Pattens-Blue-off: rgba(222, 226, 230, 0.4);
$gray-off: rgba(199, 199, 199, 0.5);
$shdow-zs-contain: 0px 0px 50px 0px rgba(0, 0, 0, 0.1);
$shadow-off: 1px 1px 1px 1px $black;
