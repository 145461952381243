@media screen and (max-width: 1399px) {

    //header
    ul.navbar-nav {
        li.nav-item {
            padding-left: 15px;
        }
    }

    // banner
    .banner {
        width: 70%;
        h1 {
            font-size: 35px;
            font-weight: 700;
        }
        h2 {
            font-size: 32px;
            font-weight: 700;
            padding-bottom: 25px;
        }
    }

    //about-us
    .zx-brands {
        .zx-approach {
            .zx-box {
                margin: 0 24px 0 0;
            }
        }
    }

    // landing-page
    .zx-app-banner {
        h1 {
            font-size: 45px;
            font-weight: 700;
            margin: 0;
        }
        h2 {
            font-size: 35px;
            margin: 0;
            font-weight: 700;
            padding-bottom: 15px;
        }
    }

    .zx-app-work {
        .zx-app-profile {
            &:not(:last-child) {
                padding-bottom: 20px;
            }
        }
    }

    // hero-contain
    .zx-app-banner {
        .theme_1 {
            bottom: 0px;
            left: 200px;
            width: 190px;
        }
    }

    .hero-content-img {
        &:last-child {
            &::after {
                height: 460px;
                width: 460px;
                bottom: 122px;
                right: 46px;
            }
        }
    }

    .footer-theme-5 {
        left: 350px;
    }

    .hero-content-img {
        height: calc(85vh - 70px);
    }
    .hero-content {
        height: calc(85vh - 70px);
    }

    .section-benefits {
        .benefits-shape-4 {
            left: 30px;
        }
        .benefits-shape-7 {
            width: 150px;
        }
    }
    .section-testimonals {
        .services-shape-8 {
            bottom: -68px;
            left: -95px;
            width: 200px;
        }
    }

    .about-contain {
        p {
            font-size: 19px;
        }
    }

    .banner-main {
        .zx-app-paragraph {
            padding-top: 30px;
        }
        .zx-app-reviews {
            .theme_2 {
                width: 70px;
                position: absolute;
                right: 30px;
                top: 55px;
            }
        }
        .zx-app-rounded {
            img {
                width: 60px;
            }
        }
    }

    // faq-page
    .accordion {
        .accordion-item {
            &:last-child{
                display: none;
            }
        }
    }

    .faq-image-border {
        &::after {
            height: 280px;
            bottom: 150px;
        }
        &::before {
            height: 280px;
            top: 150px;
        }
    }

}

@media screen and (max-width: 1199px) {

    .about-us {
        p {
            &:last-child {
                display: none;
            }
        }
    }

    .zx-brands {
        .zx-approach {
            &:not(:last-child) {
                margin-bottom: 25px;
            }
        }
    }

    //header
    ul.navbar-nav {
        li.nav-item {
            padding-left: 0;
        }
    }

    //banner
    .zx-bg-title {
        p {
            margin-top: 20px;
        }
    }

    .banner {
        width: 80%;
    }

    .about-us-image {
        height: 500px;
    }

    // top-overlay effecat
    .overlay {
        height: 650px;
    }

    //about-page
    .zx-counters {
        .zx-element-counters {
            h2 {
                font-size: 38px;
            }

            p {
                font-size: 16px;
            }
        }
    }

    .zx-app-banner {
        h1 {
            font-size: 40px;
        }
        .theme_1 {
            bottom: 0px;
            left: 180px;
            width: 160px;
        }
    }

    // footer
    .zx-footer {
        ul.footer-main {
            li {
                a {
                    line-height: 22px;
                }
            }
        }
    }

    // lading-page
    .zx-app-control .form-control {
        width: 150px;
    }

    .footer-theme-5 {
        left: 300px;
    }

    .zx-app-zorox {
        &:nth-child(1) {
            padding-bottom: 25px;
        }
        &:nth-child(2) {
            padding-bottom: 25px;
        }

        &:nth-child(3) {
            padding-top: 25px;
        }

        &:nth-child(4) {
            padding-top: 25px;
        }
    }

    // .mobaile-viwe {
    //     width: calc(100% - 20px);
    //     height: calc(100vh - 300px);
    // }

    .zx-app-work {
        .zx-app-profile {
            h5 {
                font-size: 19px;
            }
            .active-read {
                padding-top: 6px;
            }
        }
    }

    .banner-main {
        .zx-app-reviews {
            .theme_2 {
                right: -30px;
            }
        }
    }

    //faq
    .accordion {
        .accordion-item {
            margin-top: 10px;
            .accordion-button {
                h5 {
                    font-size: 16px;
                    margin-bottom: 0px;
                }
            }
        }
    }

    .hero-content-img {
        height: calc(75vh - 70px);
    }
    .hero-content {
        height: calc(75vh - 70px);
    }

    .faq-image-border {
        &::after {
            height: 250px;
            bottom: 115px;
        }
        &::before {
            height: 250px;
            top: 115px;
        }
    }

    // hero-contain
    .hero-content-img {
        &:last-child {
            &::after {
                height: 380px;
                width: 380px;
                bottom: 103px;
                right: 40px;
            }
        }
    }
}

@media screen and (max-width: 991px) {
    .reverse-order-on-mobile {
        flex-direction: column-reverse;
    }
    .theme-1 {
        display: none;
    }
    .solutions {
        max-width: 500px;
    }
    .hero-content {
        height: calc(70vh - 70px);
    }
    .zx-breadcrumb {
        margin-top: 62px;
    }
    .header-fixed{
        padding: 0 15px;
        .navbar {
            padding: 10px 0;
        }
    }
    .section-faq {
        .faq-image {
            margin-bottom: 30px;
        }
    }
    .zx-app-accouts {
        h4 {
            font-size: 18px;
        }
    }
    .hero-content-img {
        height: calc(90vh - 70px);
        margin-top: 30px;
        position: relative;
        display: block;
        &:last-child{
            &:after {
                height: 580px;
                width: 580px;
                bottom: 120px;
                right: 65px;
            }
        }
    }
    .testimonial-image {
        margin-bottom: 30px;
    }
    .zx-plans {
        max-width: 450px;
        margin: auto;
    }
    //header
    header {
        padding: 15px 0;
    }
    .zx-control {
        margin-bottom: 15px;
        form {
            margin: 0;
        }
        .form-control {
            width: 100%;
            border-radius: 5px;
            background-color: #eee;
        }
    }
    .zx-main-menu {
        display: none !important;
    }
    .d-991 {
        display: none !important;
    }
    .dq-brand, .dark-mode {
        width: 80px;
    }
    .zx-tool-btn {
        .dark-mode {
            width: 100%;
        }
    }
    .header-fixed {
        .dq-brand, .dark-mode {
            width: 70px;
        }
    }
    ul.navbar-nav {
        li.nav-item {
            padding-left: 40px;
        }
    }

    //banner
    .banner {
        width: 100% !important;

        h1 {
            font-size: 30px;
        }

        h2 {
            max-width: 450px;
            font-size: 28px;
        }
    }

    .main-plan {
        &:not(:last-child) {
            margin-bottom: 30px;
        }
    }

    .tablist-content {
        &:nth-child(1) {
            margin-bottom: 30px;
        }

        &:nth-child(2) {
            margin-bottom: 30px;
        }
    }

    .about-contain {
        margin-top: 30px;
    }

    .about-us-image {
        height: auto;
    }

    .zx-presence {
        margin: 35px 0;
    }

    .zx-brands {
        .zx-approach {
            &:not(:last-child) {
                margin-bottom: 30px;
            }
        }
    }

    // top-overlay effecat
    .overlay {
        height: 550px;
    }

    // them-effect
    .line-effect {
        top: 35%;
    }

    // .mobaile-viwe {
    //     height: calc(81vh - 70px);
    //     width: calc(96vh - 270px);
    // }

    .services-image {
        height: calc(81vh - 70px);
        width: calc(100% - 80px);
        margin: auto;
        display: flex;
    }

    .faq-image {
        height: calc(81vh - 70px);
        width: calc(100% - 80px);
        margin: auto;
        display: flex;
    }

    .lp-swip-services {
        margin-top: 25px;
    }
    //common
    // .padding-tb-100 {
    //     padding: 80px 0;
    // }

    .padding-t-100 {
        padding-top: 80px;
    }

    .padding-b-100 {
        padding-bottom: 80px !important;
    }

    .padding-tb-50 {
        padding: 40px 0;
    }

    .padding-t-50 {
        padding-top: 40px;
    }

    .padding-b-50 {
        padding-bottom: 40px;
    }

    .margin-t-50 {
        margin-top: 40px;
    }

    .margin-b-50 {
        margin-bottom: 40px;
    }
    .zx-app-testimonals {
        img {
            width: 100%;
        }
    }
    .spacing-991 {
        > div {
            &:not(:last-child) {
                margin-bottom: 30px;
            }
        }
    }
    // contact-page
    .control-input {
        margin-top: 30px;
    }

    //about-page
    .counters {
        &:nth-child(1) {
            margin-bottom: 30px;
        }
        &:nth-child(2) {
            margin-bottom: 30px;
        }
    }

    .zx-counters {
        .zx-element-counters {
            h2 {
                font-size: 35px;
            }
        }
    }

    .zx-app-banner {
        .theme_1 {
            bottom: 0px;
            left: 160px;
            width: 150px;
        }
    }

    //contant
    .zx-button {
        margin-top: 30px;
        margin-bottom: 30px;
    }

    // footer
    .footer-lg {
        &:nth-child(1) {
            margin: 0 0 30px 0;
        }

        &:nth-child(2) {
            margin: 0 0 30px 0;
        }
    }

    .footer-theme-5 {
        left: 200px;
    }
    // border
    .bordered-right {
        &:nth-child(2) {
            border: none;
        }

        &:nth-child(3) {
            margin-top: 20px;
            border-top: 1px solid;
        }

        &:nth-child(4) {
            margin-top: 20px;
            border-top: 1px solid;
        }
    }

    .blog-image {
        margin-top: 30px;
    }

    // lading-page
    .zx-app-control {
        margin-left: 38px;
    }

    .zx-app-banner {
        h4 {
            margin: 0;
            font-size: 15px;
        }
        h1 {
            max-width: 400px;
            font-size: 36px;
        }

        h2 {
            font-size: 30px;
            padding-bottom: 30px;
        }
    }
    .section-work {
        .work-image {
            margin-bottom: 30px;
        }
    }
    .pagipagination-services {
        .swiper-pagination-bullets.swiper-pagination-horizontal,
        .swiper-pagination-custom,
        .swiper-pagination-fraction {
            bottom: 0%;
        }
    }

    .banner-main {
        .zx-app-reviews {
            .theme_2 {
                position: absolute;
                right: 210px;
                top: 60px;
            }
        }
    }

    .zx-app-work {
        .zx-app-profile {
            &:not(:last-child) {
                padding-bottom: 40px;
            }
            h5 {
                font-size: 18px;
                line-height: 1.5;
            }
            .active-read {
                padding-top: 6px;
            }
        }
    }
    .zx-app-benefits, .zx-app-plans {
        h4 {
            font-size: 18px;
        }
    }
    

    // faq-page
    .accordion {
        .accordion-item {
            margin-top: 25px;
            .accordion-button {
                h5 {
                    font-size: 20px;
                    line-height: 1.4;
                }
            }
        }
    }

    .faq-image-border {
        &::after {
            height: 280px;
            bottom: 180px;
        }
        &::before {
            height: 280px;
            top: 180px;
        }
    }

    //testimonals
    .zx-app-abouts {
        .about-image {
            width: 100%;
        }
    }

    .zx-app-testimonals-position {
        margin-top: -70px;
        position: relative;
        width: 100%;
    }

    // footer
    .zx-app-fotter-banner {
        h2 {
            font-size: 36px;
            line-height: 1.42;
        }
    }

    .subscribe {
        h4 {
            font-size: 18px;
        }
    }

    .zx-app-footer-control {
        margin-bottom: 40px;
        span {
            right: 10px;
        }
    }

    .zx-app-footer {
        margin-bottom: 30px;
    }

    .zx-app-last-footer {
        padding: 15px 0;
    }

    .border-bottom {
        padding-bottom: 0;
    }

    .banner-theme-2 {
        width: 200px;
        right: -133px;
    }

    .banner-theme-3 {
        width: 200px;
        left: -133px;
    }

    .work-shape-1 {
        top: 90px;
        left: 40px;
        width: 55px;
    }

    .work-shape-3 {
        right: 50px;
        top: 600px;
        width: 60px;
    }

    .work-shape-2 {
        bottom: -50px;
        left: 100px;
        z-index: -1;
        width: 100px;
    }

    ul.footer-main {
        li {
            a {
                img {
                    margin-top: 20px;
                    width: 30px;
                    margin-right: 5px;
                }
            }
            h4 {
                font-size: 20px;
                margin-bottom: 12px;
            }
            h6 {
                margin-top: 10px;
            }
        }
        li.zx-app-image-store {
            padding: 20px 0 0 0;
            img {
                margin: 2px;
            }
        }
    }
}

@media screen and (max-width: 767px) {
    .hero-content-img {
        height: calc(60vh - 70px);
    }
    .banner {
        h2 {
            font-size: 26px;
        }
    }
    .zx-app-banner {
        h1 {
            font-size: 34px;
        }
        h2 {
            font-size: 28px;
        }
        .theme_1 {
            left: 150px;
        }
    }
    .enjoy {
        display: none;
    }
    .zx-app-fotter-banner {
        h2 {
            font-size: 32px;
            line-height: 1.42;
        }
    }
    .plus-effcat {
        display: none;
    }
    //news-page
    .zx-news {
        &:nth-child(2) {
            margin-top: 20px;
        }

        &:nth-child(3) {
            margin-top: 20px;
        }

        &:nth-child(4) {
            margin-top: 20px;
        }

        &:nth-child(5) {
            margin-top: 20px;
        }

        &:nth-child(6) {
            margin-top: 20px;
        }

        &:nth-child(7) {
            margin-top: 20px;
        }

        &:nth-child(8) {
            margin-top: 20px;
        }
    }

    .zx-portfolio {
        &:nth-child(1) {
            margin-bottom: 0;
        }

        margin-bottom: 25px !important;
    }

    // blog-page
    .zx-blog-inner {
        &:nth-child(1) {
            margin-bottom: 25px;
        }

        &:nth-child(2) {
            margin-bottom: 25px;
        }
    }
    .digital-blog {
        p,a {
            font-size: 14px;
        }
    }

    // top-overlay effecat
    .overlay {
        height: 550px;
    }

    //contant
    .zx-button {
        margin-top: 20px;
        margin-bottom: 20px;
    }

    // footer
    .zx-footer {
        .footer-lg {
            &:not(:last-child) {
                margin-bottom: 30px;
            }

            ul.footer-main {
                li {
                    h4.title {
                        margin: 10px 0;
                    }

                    h4 {
                        margin: 10px 0;
                    }
                }

                li.zx-ped {
                    &:not(:last-child) {
                        margin-bottom: 5px !important;
                    }
                }
            }
        }
    }

    .footer-theme-5 {
        left: 100px;
    }
    .zx-app-zorox{
        &:nth-child(1), &:nth-child(2), &:nth-child(3), &:nth-child(4) {
            padding: 0 12px 30px 12px;
            border: 0;
        }
    }
    .bordered-right {
        &:nth-child(1) {
            border-right: none;
        }

        &:nth-child(2) {
            border-right: none;
            border-top: 1px solid;
            margin-top: 20px;
        }

        &:nth-child(3) {
            margin-top: 20px;
            border-top: 1px solid;
            border-right: none;
        }

        &:nth-child(4) {
            margin-top: 20px;
            border-top: 1px solid;
        }
    }

    .mobaile-viwe {
        width: 100%;
        height: auto;
        max-height: 50vh;
    }

    .services-image {
        width: 100%;
        height: auto;
    }

    .faq-image {
        width: 100%;
        height: auto;
    }

    // lending-page
    .banner-main {
        .zx-app-reviews {
            .theme_2 {
                position: absolute;
                right: 20px;
                top: 55px;
            }
        }
    }

    .footer-theme-4 {
        right: 79px;
        bottom: -26px;
        width: 72px;
    }

    .hero-content-img {
        &:last-child {
            &::after {
                height: 440px;
                width: 440px;
                bottom: 10px;
                right: 42px;
            }
        }
    }

    // faq-page
    .faq-image-border {
        &::after {
            height: 280px;
            bottom: 130px;
        }
        &::before {
            height: 280px;
            top: 130px;
        }
    }
    .section-banner {
        .top_outline_01, .top_outline_02, .bottom_outline_01, .bottom_outline_02{
            opacity: .2;
        }
    }
}

@media screen and (max-width: 575px) {
    .pagipagination-testimonial {
        position: relative;
        .swiper-pagination-bullets.swiper-pagination-horizontal {
            bottom: 60px;
        }
    }
    .zx-app-fotter-banner {
        h2 {
            font-size: 28px;
        }
    }
    .top_outline_01, .top_outline_02 {
        display: none;
    }
    .hero-content {
        height: calc(60vh - 70px);
    }
    .banner-theme-3 {
        display: none;
    }
    .zx-app-banner {
        h1 {
            font-size: 30px;
        }
        .theme_1 {
            left: 133px;
        }
    }
    .banner {
        h1 {
            font-size: 25px;
        }
    }
    .banner-main {
        .zx-app-reviews {
            padding: 30px 0 0 0;
            .reviews-inner {
                h4 {
                    display: none;
                }
            }
            .zx-app-icons, .theme_2 {
                display: none;
            }
        }
        .zx-app-rounded {
            display: none;
        }
    }
    .banner {
        h2 {
            font-size: 22px;
        }
    }
    .zx-app-work {
        &:after {
            left: 27px;
        }
        .zx-app-profile {
            .profile-image {
                width: 55px;
                height: 55px;
                margin-right: 20px;
                img {
                    width: 25px;
                    height: 25px;
                }
            }
        }
    }
    // header
    nav.navbar {
        padding: 0 15px;
    }
    .banner-image {
        padding: 0 15px;
    }
    //common
    // .padding-tb-100 {
    //     padding: 60px 0;
    // }

    .padding-t-100 {
        padding-top: 60px;
    }

    .padding-tb-50 {
        padding: 30px 0;
    }

    .padding-b-100 {
        padding-bottom: 60px !important;
    }

    .padding-t-50 {
        padding-top: 30px;
    }

    .padding-b-50 {
        padding-bottom: 30px;
    }

    .margin-t-50 {
        margin-top: 30px;
    }

    .margin-b-50 {
        margin-bottom: 30px;
    }

    // top-overlay effecat
    .overlay {
        height: 550px;
    }

    //contant
    .zx-button {
        margin-top: 15px;
        margin-bottom: 15px;
    }

    //about-page
    .counters {
        &:not(:last-child) {
            margin-bottom: 30px;
        }
    }

    // footer
    .zx-footer {
        .footer-lg {
            &:not(:last-child) {
                margin-bottom: 15px;
            }
        }
    }

    //potfolio-page
    .zx-serch-box {
        .zoom-image {
            img {
                transform: scale(2);
            }
        }
    }

    // landing-page
    .footer-theme-3 {
        display: none;
    }

    .footer-theme-5 {
        left: -15px;
    }

    .banner-theme-1 {
        position: absolute;
        width: 40px;
        top: 60px;
        left: 0px;
    }

    .theme_3 {
        width: 80px;
        position: absolute;
        bottom: 90px;
        right: 0;
    }

    .work-shape-1 {
        position: absolute;
        top: 40px;
        left: 30px;
    }

    .work-shape-3 {
        position: absolute;
        right: 30px;
        top: 530px;
    }

    .footer-theme-1 {
        position: absolute;
        right: -34px;
        width: 80px;
    }

    .footer-theme-4 {
        width: 50px;
    }

    .hero-content-img {
        &:last-child {
            &::after {
                display: none;
            }
        }
    }

    // faq-page
    .faq-image-border {
        &::after {
            border: none;
        }
        &::before {
            border: none;
        }
    }
    .zx-app-banner {
        h2 {
            font-size: 22px;
        }
    }
    .section-faq {
        overflow: hidden;
    }
}
@media screen and (max-width: 480px) {
    .hero-content-img {
        height: calc(50vh - 70px);
    }
}
@media screen and (max-width: 420px) {
    .zx-bg-title .zx-banner-button {
        flex-direction: column;
        align-items: center;
        justify-content: center;
        .zx-coling {
            padding: 0;
        }
    }
    .hero-content-img {
        height: calc(45vh - 70px);
    }
    .zx-app-banner {
        // h1 {
        //     font-size: 26px;
        // }
        .theme_1 {
            left: 115px;
            width: 120px;
        }
    }
    .zx-brands {
        .zx-approach {
            flex-direction: column;
            .zx-box {
                margin: 0 0 24px 0;
            }
        }
    }
}
@media screen and (max-width: 360px) {
    .hero-content-img {
        height: calc(42vh - 70px);
    }
    .zx-app-banner {
        h1 {
            font-size: 24px;
        }
        .theme_1 {
            left: 110px;
            width: 110px;
        }
    }
}