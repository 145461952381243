/* Header */
header {
	box-shadow: 2px 2px 15px 0 rgba(0, 0, 0, .09);
	position: absolute;
	top: 0;
	right: 0;
	left: 0;
	z-index: 2;
}

.header-fixed {
	position: fixed;
	box-shadow: 0 5px 16px rgba(0, 0, 0, 0.1);
	padding: 0px 0;
	backdrop-filter: blur(10px);
	animation: slideDown 0.35s ease-out;
	z-index: 40;
	.zx-main-menu {
		ul {
			li {
				a {
					padding: 0;
				}
			}
		}
	}
}

@keyframes slideDown {
	from {
		transform: translateY(-100%);
	}

	to {
		transform: translateY(0);
	}
}

.navbar-brand {
	margin: 0;
}

.zx-buttons {
	width: 150px;
	padding: 10px 25px;
	background-color: $Pattens-Violet;
	color: $white;
	line-height: 20px;
	font-size: 16px;
	font-weight: 700;
	border: 1px solid $Pattens-Violet;
	border-radius: 20px;
	transition: 0.3s ease-in-out;

	&:hover {
		background-color: $white;
		color: $Pattens-Violet;
	}
}

.navbar {
	padding: 0;
	position: relative;
	z-index: 3;
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.zx-control {
	display: flex;
	align-items: center;

	.form-control {
		position: relative;
		width: 200px;
		border-color: $Pattens-Blue;
		border-radius: 25px;
	}

	i {
		position: absolute;
		z-index: 2;
		padding: 12px;
	}

	button {
		padding: 0 25px;
		border-radius: 25px;
	}
}

.dq-brand {
	display: block;
}

.dark-mode {
	display: none;
}

.zx-main-menu {
	display: flex;
	position: relative;

	ul {
		width: 100%;
		margin: 0;
		display: flex;
		justify-content: center;
		flex-wrap: wrap;
		padding-left: 0;
		list-style: none;

		li {
			@include ease3;
			margin: 0 20px;

			a {
				@include ease3;
				padding: 10px 0;
				font-size: 15px;
				line-height: 60px;
				text-decoration: none;
				text-transform: capitalize;
				color: $medium-black;
				display: flex;
				align-items: center;
				font-weight: 500;
				position: relative;

				i {
					@include ease3;
					margin-right: 5px;
					font-size: 18px;
					color: $medium-black;
					display: flex;
				}
			}

			&:hover {
				>a {
					color: $Pattens-Violet;

					&:after {
						color: $Pattens-Violet;
					}

					.svg_img {
						path {
							fill: $Pattens-Violet;
						}
					}
				}
			}

			.mega-menu {
				@include ease3;
				width: 100%;
				margin-top: 15px;
				position: absolute;
				background: $white;
				padding: 0 0 0 30px !important;
				opacity: 0;
				visibility: hidden;
				left: 0;
				z-index: 15;
				border-radius: 10px;
				box-shadow: 2px 2px 15px 0 rgba(0, 0, 0, .07);
				border: 1px solid $gray;
				overflow: hidden;

				li {
					ul {
						padding: 15px 0;
					}

					.mega-block {
						width: calc(25% - 30px);
						margin-right: 30px;

						li.menu_title {
							a {
								color: $Pattens-Violet;
								font-size: 15px;
								font-weight: 500;
								display: block;
								border-bottom: 1px solid $gray;
								margin-bottom: 10px;
								padding-bottom: 5px;
								height: auto;

								&:hover {
									color: $Pattens-Violet;
								}
							}
						}

						li {
							border: 0 !important;

							a {
								color: $gray;
								text-transform: capitalize;
								line-height: 30px;
								font-weight: 400;
								font-size: 13px;
								display: block;
								padding: 5px 0;
								border: 0;
								height: auto;

								&:hover {
									color: $Pattens-Violet;
								}
							}
						}
					}
				}
			}
		}

		li.dropdown {
			position: relative;

			a {
				padding-right: 20px;
			}

			ul.sub-menu {
				@include ease3;
				margin: 15px 0 0 0;
				padding: 10px;
				position: absolute;
				z-index: 16;
				text-align: left;
				opacity: 0;
				visibility: hidden;
				min-width: 205px;
				left: 0;
				right: auto !important;
				background: $white;
				box-shadow: 2px 2px 15px 0 rgba(0, 0, 0, .09);
				display: block;
				border-radius: 10px;
			}

			ul {
				padding-top: 5px;
				padding-bottom: 5px;

				li.active {
					a {
						color: $Pattens-Violet;

						i {
							color: $Pattens-Violet;
						}
					}
				}

				li {
					padding: 0;
					margin: 0;
					display: block;

					&:hover {
						>a {
							color: $Pattens-Violet;

							i {
								color: $Pattens-Violet;
							}
						}
					}

					a {
						@include ease3;
						display: block;
						line-height: 20px;
						padding: 10px 20px;
						font-weight: 500;
						font-size: 14px;
						color: $gray;
						text-transform: capitalize;
						display: flex;
						justify-content: space-between;
						align-items: center;

						&:hover {
							color: $Pattens-Violet;

							i {
								color: $Pattens-Violet;
							}
						}

						i {
							@include ease3;
							color: $gray;
							position: absolute;
							right: 2px;
						}
					}

					&:first-child {
						border-top: none;
					}
				}
			}

			li.position-static {
				position: relative !important;

				&:hover {
					.sub-menu.sub-menu-child {
						margin: -5px 0 0 0;
						opacity: 1;
						visibility: visible;
					}
				}
			}

			ul.sub-menu.sub-menu-child {
				@include ease3;
				margin: 30px 0 0 0;
				left: 100% !important;
				opacity: 0;
				visibility: hidden;
				top: 0;
			}

			&:hover {
				.sub-menu {
					margin-top: 0;
					opacity: 1;
					visibility: visible;
				}

				.mega-menu {
					margin-top: 0;
					opacity: 1;
					visibility: visible;
				}
			}
		}

		.dropdown-arrow {
			position: relative !important;

			i {
				position: absolute;
				top: 50%;
				right: -5px;
				font-size: 13px;
				transform: translateY(-50%);
			}
		}

		li.active {
			>a {
				color: $Pattens-Violet;
			}
		}
	}

	>ul {
		>li {
			&:hover {
				>a {
					&:before {
						background: $Pattens-Violet;
						width: 100%;
					}

					i {
						color: $Pattens-Violet;
					}
				}
			}

			>a {
				&:before {
					content: "";
					position: absolute;
					top: auto;
					bottom: 0;
					width: 0px;
					height: 3px;
					margin: 0 auto;
					background: #ffffff;
					left: 0;
					right: auto;
					transition: all 0.3s ease 0s;
				}
			}
		}

		>li.active {
			>a {
				&:before {
					background: $Pattens-Violet;
					width: 100%;
				}
			}
		}
	}
}

.navbar-toggler {
	padding: 5px 7px;

	&:focus {
		box-shadow: none;
	}
}

.zx-sidebar-overlay {
	display: none;
	width: 100%;
	height: 100vh;
	position: fixed;
	top: 0;
	left: 0;
	background-color: rgba(0, 0, 0, 0.8);
	z-index: 42;
}

.zx-mobile-menu {
	-webkit-transition: all 0.3s ease-in-out;
	transition: all 0.3s ease-in-out;
	width: 300px;
	height: 100%;
	padding: 15px 20px 20px 20px;
	position: fixed;
	top: 0;
	right: auto;
	left: 0;
	background-color: #fff;
	-webkit-transform: translateX(-100%);
	transform: translateX(-100%);
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-ms-flex-direction: column;
	flex-direction: column;
	z-index: 43;
	overflow-x: auto;

	.zx-menu-inner {
		.zx-menu-content {
			ul {
				margin: 0;
				padding: 0;

				li {
					position: relative;
					list-style-type: none;

					.menu-toggle {
						width: 100%;
						height: 47px;
						padding-right: 15px;
						position: absolute;
						z-index: 2;
						top: 0;
						right: 0;
						left: auto;
						cursor: pointer;
						background-color: transparent;
						display: -webkit-box;
						display: -ms-flexbox;
						display: flex;
						-webkit-box-align: center;
						-ms-flex-align: center;
						align-items: center;
						justify-content: flex-end;

						&:before {
							content: "+";
							font-weight: 500;
							color: $gray;
							font-size: 24px;
						}
					}

					a {
						margin-bottom: 12px;
						padding: 12px 12px;
						display: block;
						text-transform: capitalize;
						color: $gray;
						border: 1px solid #eee;
						border-radius: 5px;
						font-size: 15px;
						font-weight: 500;
						text-decoration: none;
					}

					.sub-menu {
						width: 100%;
						min-width: auto;
						padding: 0;
						margin-bottom: 0;
						position: static;
						top: auto;
						display: none;
						visibility: visible;
						-webkit-transition: none;
						transition: none;
						opacity: 1;
						-webkit-box-shadow: none;
						box-shadow: none;

						li {
							&:last-child {
								margin-bottom: 15px;
							}

							a {
								margin: 0;
								border: 0;
								font-size: 14px;
							}
						}
					}
				}
			}
		}
	}

	.zx-menu-title {
		width: 100%;
		padding: 0 0 10px 0;
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
		-ms-flex-wrap: wrap;
		flex-wrap: wrap;
		-webkit-box-pack: justify;
		-ms-flex-pack: justify;
		justify-content: space-between;

		.zx-close-menu {
			position: relative;
			border: 0;
			font-size: 30px;
			line-height: 1;
			color: $gray;
			background-color: transparent;
			border: 0;
		}

		.menu_title {
			display: -webkit-box;
			display: -ms-flexbox;
			display: flex;
			-webkit-box-align: center;
			-ms-flex-align: center;
			align-items: center;
			font-size: 16px;
			color: #4b5966;
			font-weight: 600;

			.zx-close-menu {
				position: relative;
				border: 0;
				font-size: 30px;
				line-height: 1;
				color: $gray;
				background-color: transparent;
				border: 0;
			}
		}
	}
}

.zx-mobile-menu.zx-menu-open {
	-webkit-transform: translateX(0);
	transform: translateX(0);
	opacity: 1;
}