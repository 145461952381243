/*  General css  */
body {
  font-size: 15px;
  font-family: $text-font;
  font-weight: 400;
  color: $black;
  overflow-x: hidden !important;
}

p {
  font-family: $text-font;
  font-size: 15px;
  font-weight: 400;
  line-height: 24px;
  color: $gray;
  margin-bottom: 0;
}
// Loader
.zx-loader {
  min-width: 100%;
  width: 100%;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #ffffff;
  z-index: 50;
  .loader {
    width: 100px;
    height: 100px;
    display: inline-block;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    &::after,
    &::before {
      content: "";
      width: 50px;
      height: 50px;
      border-radius: 50%;
      border: 50px solid $Pattens-Violet;
      position: absolute;
      left: 0;
      top: 0;
      opacity: 0;
      animation: animloader14 2s linear infinite;
    }
    &::after {
      animation-delay: 1s;
    }
  }
  @keyframes animloader14 {
    0% {
      transform: scale(0);
      opacity: 1;
    }
    100% {
      transform: scale(1);
      opacity: 0;
    }
  }
}
.padding-t-100 {
  padding: 100px 0 0 0;
}

.padding-b-100 {
  padding-bottom: 100px !important;
}

.padding-tb-100 {
  padding: 100px 0;
}

.padding-tb-50 {
  padding: 50px 0;
}

.padding-t-50 {
  padding: 50px 0 0 0;
}

.padding-b-50 {
  padding-bottom: 50px;
}

.margin-t-50 {
  margin-top: 50px;
}

.margin-tb-100 {
  margin: 100px 0;
}

.margin-b-50 {
  margin-bottom: 50px;
}

.pos_relative {
  position: relative;
}

.w-70 {
  max-width: 750px;
}
.w-60 {
  max-width: 600px;
}

.p-10 {
  padding-bottom: 10px;
}

.w-14 {
  width: 14%;
}

.w-12 {
  width: 12%;
}

.w-86 {
  width: 86%;
}

.t-left {
  text-align: left !important;
  float: left !important;
}

.mt-20 {
  margin-top: 20px;
}

.mb-0 {
  margin-bottom: 0 !important;
}
.mb-m-24 {
  margin-bottom: -24px;
}
.mb-m-30 {
  margin-bottom: -30px;
}
.center {
  text-align: center !important;
}

//banner
.banner {
  text-align: center;
  margin: auto;
  display: flex;

  h1 {
    font-family: $title-font;
    font-size: 43px;
    font-weight: 700;
    line-height: 1.42;
    margin: 0;
  }

  h2 {
    max-width: 600px;
    margin: 0;
    font-family: $title-font;
    font-size: 40px;
    font-weight: 700;
    line-height: 1.2;
    padding-bottom: 30px;
  }
}

.swiper-pagination-horizontal.swiper-pagination-bullets {
  .swiper-pagination-bullet {
    background-color: $Pattens-Violet;
  }
}

//tap to top
 a.back-to-top {
  position: fixed;
  right: 15px;
  bottom: 15px;
  z-index: 10;
  cursor: pointer;
  // display: none;
  width: 38px;
  height: 38px;
  border-radius: 20px;
  background-color: $Pattens-Violet;
  color: $white;
  text-align: center;
  font-size: 22px;
  line-height: 1.6;
  i {
    font-size: 20px;
  }

  &:hover {
    transition: all 0.3s ease-in-out;
  }
}

//breadcrumb
.zx-breadcrumb {
  margin-top: 80px;
  background-color: $guyabano;
  .mb-15 {
    margin-bottom: -15px !important;
  }

  .entry-breadcrumb {
    text-align: center;

    h5 {
      margin-bottom: 0;
      font-size: 16px;

      span {
        color: $black;

        a {
          text-decoration: none;
          color: $black;
          font-family: $text-font;
          font-size: 16px;
          font-weight: 500;
        }
      }

      .post-page {
        color: $black;
        opacity: 0.7;

        a {
          text-decoration: none;
          color: $black;
          font-family: $text-font;
          font-size: 16px;
          font-weight: 500;
        }
      }
    }
  }
}

.zx-tool-active {
  right: 0 !important;
}
.zx-tool {
  position: fixed;
  top: 350px;
  width: 180px;
  right: -140px;
  z-index: 20;
  transition: all 0.4s ease;
  color: $black;

  .zx-tool-btn {
    display: flex;

    i {
      font-size: 20px;
    }
    .color-variant {
      ul.dark-mode {
        li {
          display: block;
          i {
            color: #777;
          }
        }
        .white {
          display: none;
        }
      }
    }
    text-decoration: none;
    color: $black;

    button {
      border: none;
      background: none;
      background-color: $Pattens-Violet;
      color: $white;
      height: 40px;
      width: 40px;
      padding: 0 10px;
      border-top-left-radius: 10px;
      border-bottom-left-radius: 10px;
    }

    .color-variant {
      padding: 0;
      margin: 0;
      background-color: $white;
      border: 2px solid $Pattens-Violet;

      .heading {
        background-color: $Pattens-Violet;

        h2 {
          padding: 9px 0;
          text-align: center;
          font-size: 17px;
          color: $white !important;
          font-weight: 700;
          margin-bottom: 0;
        }
      }

      ul.zx-color {
        margin: 0;
        padding: 0;

        li {
          display: inline-block;
          height: 30px;
          width: 30px;
          border-radius: 5px;
          cursor: pointer;
          vertical-align: middle;
          margin: 5px 5px;
          position: relative;
        }
      }

      ul.dark-mode {
        margin: 0;
        padding: 0;
        display: flex;
        justify-content: center;

        li {
          display: inline-block;
          border-radius: 5px;
          cursor: pointer;
          vertical-align: middle;
          margin: 7px 7px;

          i {
            font-size: 23px;
          }
        }
      }
    }
  }
}

.active-variant {
  &:before {
    font-family: "Font Awesome 6 Free";
    content: "\f00c";
    font-size: 18px;
    padding: 1px;
    font-weight: 700;
    display: flex;
    justify-content: center;
    text-align: center;
    color: $white;
  }
}

//====================================== tool { color change } ======================================
.yellow-and-white {
  background-color: $light-orange;
  color: $white;
}

// green
.green-and-white {
  background-color: $color-green;
  color: $white;
}

// Pattens-Violet
.Pattens-Violet-and-white {
  background-color: $Pattens-Violet;
  color: $white;
}

// Light-Grey
.Light-Grey-and-white {
  background-color: #6e84ff;
  color: $white;
}

// LightCoral
.LightCoral-and-white {
  background-color: $LightCoral;
  color: $white;
}

// slvid
.slvid {
  background-color: $LightCoral;
  color: $white;
}

// border
.bordered-top {
  border-top: 1px solid $gray;
}

.bordered-right {
  &:not(:last-child) {
    border-right: 1px solid $gray;
  }

  &:hover {
    border-right: none;
    border-left: none;
    padding: 0;
  }
}
