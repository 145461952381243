
/* About page */
.zx-counters{
    @include ease3;
    padding: 30px 10px;
    border-radius: 10px;
    border: 1px solid transparent;
    background-color: $white;
    box-shadow: 0px 0px 15px -5px rgba(0, 0, 0, 0.15);
    &:hover {
        border: 1px solid $gray-off;
        box-shadow: none;
    }
    .zx-element-counters{
        text-align: center;
        h2{
            font-family: $title-font;
            font-size: 50px;
            font-weight: bold;
            margin: 0;
            display: flex;
            justify-content: center;
        }
        p{
            font-size: 17px;
            font-weight: 700;
            margin-top: 2px;
        }
    }
}

