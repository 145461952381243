/* Service page */
.zx-service-box{
    &:not(:last-child){
        margin-bottom: 25px;
    }
}

.zx-service {
    margin-bottom: 24px;
    padding: 30px;
    border: 1px solid transparent;
    border-radius: 10px;
    box-shadow: $box-shadow;
    background-color: $white;
    transition: all .3s ease-in-out;
    &:hover{
        border: 1px solid $gray-off;
        box-shadow: none;
        img {
            filter: invert(00%);
        }
    }
    img {
        width: 60px;
        filter: invert(30%);
        transition: all 0.3s ease-in-out;
    }
    .zx-market {
        padding-top: 20px;
        h4 {
            font-family: $title-font;
            font-size: 17px;
            font-weight: 800;
            line-height: 1.4;
            color: $black;
            margin-bottom: 10px;
        }

        p {
            font-size: 14px;
            font-weight: 400;
            margin-bottom: 0;
        }
    }
    .active-read {
        padding-top: 20px;
        display: flex;
        align-items: center;
        &:hover {
            a {
                margin-right: 10px;
            }
        }
        a {
            @include ease3;
            margin-right: 5px;
            text-decoration: none;
            color: $black;
            font-size: 13px;
            font-weight: 500;
        }
        i {
            color: $black;
        }
    }
}
