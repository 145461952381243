/* Footer 2 */
footer {
  position: relative;
  overflow: hidden;
}

.zx-app-fotter-banner {
  h2 {
    font-family: $title-font;
    font-size: 50px;
    font-weight: bold;
    line-height: 1.42;
    margin: 0;
  }
}

.subscribe {
  h4 {
    font-size: 20px;
    font-family: $title-font;
    color: $black;
    font-weight: bold;
  }
}

.zx-app-footer-control {
  display: flex;
  align-items: center;
  position: relative;
  margin-top: 20px;
  box-shadow: 0px 0px 15px -5px rgba(0, 0, 0, 0.15);
  border-radius: 10px;
  .form-control {
    border-radius: 0px;
    margin: 0;
    padding: 15px;
    background-color: #fff;
    border-bottom: 0;
    border-top: none;
    border-left: none;
    border-right: none;
    border-radius: 10px;
    font-weight: 500;
    &::placeholder {
      padding-top: 2px;
      font-weight: 800;
      color: $text-999;
      font-weight: 500;
    }
    &:focus {
      color: $gray;
      line-height: 1.5;
    }
  }

  span {
    position: absolute;
    z-index: 2;
    right: 20px;
    cursor: pointer;
  }
}
.zx-app-fotter-banner {
  height: 100%;
  display: flex;
  align-items: center;
}

.zx-app-footer {
  margin-bottom: 30px;
}

.zx-app-last-footer {
  padding: 25px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  p {
    color: $black;
    font-size: 15px;
    font-weight: normal;
    text-align: center;
    z-index: 1;
    a {
      text-decoration: none;
      span {
        color: $Pattens-Violet;
      }
    }
  }
}

.border-bottom {
  padding-bottom: 50px;
  border-bottom: 1px solid $gray-off;
}

.border-top {
  border-top: 1px solid $gray-off;
}

ul.footer-main {
  list-style-type: none;
  padding: 0;
  margin: 0;
  li {
    a {
      img {
        margin-top: 20px;
        width: 30px;
        margin-right: 5px;
      }
    }
    h4 {
      font-size: 20px;
      font-family: $title-font;
      font-weight: bold;
      line-height: 1.4;
      margin-bottom: 25px;
    }
    h6 {
      margin-top: 15px;
      a {
        color: $gray;
        text-decoration: none;
        display: block;
        font-weight: 400;
        font-size: 15px;
        line-height: 17px;
        transition: 0.3s ease-in-out;
      }
    }
  }
  li.zx-app-image-store {
    padding: 25px 0 0 0;
    display: flex;
    gap: 4px;
    img {
      width: 130px;
      border-radius: 9px;
      border: 1px solid $Pattens-Blue;
    }
  }
}
.dark-mode {
  display: none;
}
