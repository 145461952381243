/* Demo 2 page */
// buttons
.zx-app-buttons {
  border-radius: 10px;
  background-color: $Pattens-Violet;
  width: 151px;
  height: 40px;
  color: $white;
  font-size: 15px;
  font-family: $text-font;
  font-weight: bold;
  line-height: 1.867;
  border: 1px solid $Pattens-Violet;
  transition: 0.3s ease-in-out;
  &:hover {
    background-color: $white;
    color: $Pattens-Violet;
    border: 1px solid $Pattens-Violet;
  }
}

.hero-content {
  height: calc(100vh - 70px);
  display: flex;
  align-items: center;
}

.hero-content-img {
  height: calc(100vh - 70px);
  display: flex;
  //align-items: flex-end;
  align-items: center;
  justify-content: center;
  &:last-child {
    position: relative;
    &::after {
      content: "";
      border: 2px dashed $gray-off;
      //height: 540px;
      //width: 540px;
      height: 600px;
      width: 600px;
      border-radius: 100%;
      position: absolute;
      //bottom: 138px;
      //right: 53px;
      z-index: -1;
      animation: rotate 50s normal linear infinite;
    }
  }
}

@keyframes rotate {
  0% {
    transform: rotate3d(0, 0, 1, 0deg);
  }
  25% {
    transform: rotate3d(0, 0, 1, 90deg);
  }
  50% {
    transform: rotate3d(0, 0, 1, 180deg);
  }
  75% {
    transform: rotate3d(0, 0, 1, 270deg);
  }
  100% {
    transform: rotate3d(0, 0, 1, 360deg);
  }
}

.hero_image {
  max-width: 100%;
  width: 400px;
  border-radius: 16px;
}

// banner
.section-banner {
  position: relative;
  z-index: 1;
  overflow: hidden;
}

.banner-theme-1 {
  position: absolute;
  width: 40px;
  top: 80px;
  left: 35px;
  animation: leftright 1s infinite alternate;
}

.banner-theme-2 {
  position: absolute;
  top: 0;
  right: -180px;
  z-index: 0;
}

.banner-theme-3 {
  position: absolute;
  bottom: 50px;
  left: -200px;
  z-index: 0;
}
.zx-app-banner {
  margin-top: 0px;
  position: relative;
  .theme_1 {
    position: absolute;
    bottom: 0;
    left: 240px;
    opacity: 0.2;
  }
  h1 {
    font-size: 54px;
    font-family: $title-font;
    color: $black;
    font-weight: bold;
    line-height: 1.593;
    letter-spacing: 1px;
    span {
      text-decoration: 5px underline $Pattens-Violet;
    }
  }
  h2 {
    font-size: 42px;
    font-family: $title-font;
    color: $black;
    font-weight: bold;
    line-height: 1.2;
    letter-spacing: 1px;
    text-align: left;
    margin-bottom: 20px;
  }
  h4 {
    color: $Pattens-Violet;
    font-size: 16px;
    font-family: $title-font;
    font-weight: bold;
    text-transform: capitalize;
    margin-bottom: 6px;
  }
}

.banner-main {
  .zx-app-paragraph {
    padding-top: 10px;
  }
  .zx-app-reviews {
    position: relative;
    display: flex;
    padding: 30px 0;
    button {
      margin-right: 20px;
    }
    a {
      justify-content: space-evenly;
      display: flex;
      align-items: center;
      margin-right: 20px;
    }
    .reviews-inner {
      margin-right: 20px;
      display: flex;
      align-items: center;

      h4 {
        font-size: 20px;
        font-family: $title-font;
        color: $black;
        font-weight: bold;
        line-height: 1.4;
        margin-bottom: 0;
      }
    }
    .zx-app-icons {
      display: flex;
      align-items: center;

      i {
        padding-left: 3px;
        color: $yellow;
        font-size: 16px;
      }
    }
    .theme_2 {
      position: absolute;
      right: 120px;
      top: 55px;
      opacity: 0.2;
    }
  }
  .zx-app-rounded {
    img {
      @include ease3;
      width: 60px;
      border-radius: 50%;
      border: 2px solid #fff;
      box-shadow: 0px 0px 15px -5px rgba(0, 0, 0, 0.3);
      position: relative;
      z-index: 4;
      &:not(:first-child) {
        margin-left: -30px;
      }
      &:hover {
        z-index: 5;
      }
    }
  }
}
.bg-market {
  background-color: #f8f8f8;
}
.plus-effcat, .plus-effcat-1 {
  position: absolute;
  bottom: 100px;
  opacity: 0.5;
  animation: topbottom 2s infinite alternate;
}
.plus-effcat-1 {
  bottom: 0px;
}

.theme_3 {
  width: 80px;
  position: absolute;
  bottom: 90px;
  right: -30px;
}

//servish-01
.section-work {
  position: relative;
}

.work-shape-1 {
  width: 70px;
  position: absolute;
  top: 150px;
  left: 70px;
  z-index: -1;
  opacity: 0.5;
}

.work-shape-2 {
  position: absolute;
  bottom: -70px;
  left: 100px;
  transform: rotate(-3deg);
  z-index: -1;
  opacity: 0.5;
}

.work-shape-3 {
  width: 70px;
  position: absolute;
  right: 50px;
  top: 349px;
  z-index: -1;
  opacity: 0.5;
}

.work-image {
  margin-bottom: 0px;
  position: sticky;
  top: 85px;
  width: 630px;
  max-width: 100%;
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='315' ry='315' stroke='%23BFBFBF' stroke-width='7' stroke-dasharray='5%25%2c 5%25' stroke-dashoffset='86' stroke-linecap='round'/%3e%3c/svg%3e");
  border-radius: 315px;
}

.faq-image {
  max-width: 636px;
  height: 669px;
}

.faq-image-border {
  position: relative;
  &::after {
    content: "";
    border: 1px dashed $black;
    height: 280px;
    width: 100%;
    border-radius: 100%;
    position: absolute;
    bottom: 200px;
    right: 0px;
    z-index: -1;
  }
  &::before {
    content: "";
    border: 1px dashed $black;
    height: 280px;
    width: 100%;
    border-radius: 100%;
    position: absolute;
    top: 200px;
    right: 0px;
    z-index: -1;
    transform: rotate(30deg);
    animation: border-dance 4s infinite linear;
  }
  @keyframes border-dance {
    0% {
      background-position: 0px 0px, 300px 116px, 0px 150px, 216px 0px;
    }
    100% {
      background-position: 300px 0px, 0px 116px, 0px 0px, 216px 150px;
    }
  }
}
.zx-work {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.zx-app-work {
  position: relative;
  &:after {
    content: "";
    border: 1px dashed rgba(199, 199, 199, 0.5);
    height: 100%;
    width: 2px;
    position: absolute;
    top: 0;
    left: 34px;
    z-index: -1;
  }
  .zx-app-profile {
    display: inline-flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    &:not(:last-child) {
      padding-bottom: 30px;
    }
    .profile-image {
      width: 70px;
      height: 70px;
      margin-right: 24px;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      flex-wrap: wrap;
      background-color: #fff;
      border-radius: 50%;
      box-shadow: 0px 0px 15px -5px rgba(0, 0, 0, 0.3);
      img {
        width: 40px;
        height: 40px;
      }
    }
    .zx-detail {
      width: calc(100% - 94px);
    }
    h5 {
      font-size: 19px;
      font-family: $title-font;
      color: $black;
      font-weight: bold;
      line-height: 2.632;
      margin-bottom: 0px;
    }
    .active-read {
      @include ease3;
      padding-top: 12px;
      &:hover {
        i {
          margin-left: 5px;
        }
      }
      a {
        font-family: $text-font;
        text-decoration: none;
        color: $gray;
        font-size: 14px;
        font-weight: bold;
      }
      i {
        @include ease3;
        color: $gray;
      }
    }
  }
}

.zx-app-abouts {
  img {
    border-radius: 10px;
  }
}

.mobaile-viwe {
  margin: auto;
  display: block;
}

.bg-border {
  border-left: 1px dotted $Pattens-Violet;
}

//servish-02
.zx-app-accouts {
  h5 {
    border: 1px solid $gray-off;
    width: 45px;
    height: 45px;
    font-weight: bold;
    border-radius: 10px;
    padding: 7px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  h4 {
    font-size: 20px;
    font-family: $title-font;
    color: $black;
    font-weight: bold;
    padding: 10px 0;
    margin: 0;
  }
}

.lp-swip-services {
  margin-top: 0px;
}

.enjoy {
  position: absolute;
  bottom: -13px;
  right: -14px;
}

.zx-app-zorox {
  padding-left: 30px;
  padding-right: 30px;
  &:nth-child(1) {
    position: relative;
    border-right: 1px dashed $gray-off;
    border-bottom: 1px dashed $gray-off;
    padding-bottom: 30px;
  }
  &:nth-child(2) {
    border-bottom: 1px dashed $gray-off;
    padding-bottom: 30px;
  }
  &:nth-child(3) {
    border-right: 1px dashed $gray-off;
    padding-top: 30px;
  }
  &:nth-child(4) {
    padding-top: 30px;
  }
}

//servish-03
.section-benefits {
  position: relative;
  overflow: hidden;
  .benefits-shape-4 {
    position: absolute;
    position: absolute;
    bottom: 50px;
    left: 200px;
    z-index: -1;
  }
  .benefits-shape-6 {
    left: 0;
    top: 100px;
    position: absolute;
    animation: topbottom 2s infinite alternate;
    opacity: 0.5;
  }
  .benefits-shape-7 {
    position: absolute;
    top: -39px;
    right: -20px;
    width: 200px;
    z-index: -1;
  }
}

.zx-app-benefits {
  max-width: 500px;
  padding: 30px;
  margin: auto;
  background-color: $white;
  border-radius: 15px;
  box-shadow: $box-shadow;
  h4 {
    font-size: 20px;
    font-family: $title-font;
    color: $black;
    font-weight: bold;
    line-height: 1.3;
    text-align: center;
    margin: auto;
    width: 100%;
    margin-bottom: 8px;
  }
  p {
    text-align: center;
  }
  img {
    padding: 30px 0 0 0;
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 200px;
  }
}

//Testimonals
.section-testimonals {
  position: relative;
  .services-shape-8 {
    position: absolute;
    bottom: -56px;
    left: -100px;
    z-index: -1;
    width: 250px;
    opacity: 0.5;
  }
}

.services-shape-5 {
  position: absolute;
  bottom: 0px;
  right: 0px;
  z-index: -1;
  animation: topbottom 1.5s infinite alternate;
  opacity: 0.5;
}

.section-testimonals {
  .container {
    position: relative;
  }
}

.zx-app-abouts {
  .about-image {
    width: 60%;
  }
}
.section-testimonial-us {
  position: relative;
}
.zx-app-testimonals-position {
  position: absolute;
  width: 50%;
  top: 0;
  right: 0;
  z-index: 15;
  overflow: hidden !important;
  .zx-app-testimonial {
    padding: 15px;
  }
  .zx-app-testimonals-domain {
    box-shadow: $box-shadow;
    background-color: $white;
    padding: 30px;
    border-radius: 15px;
    img {
      width: 70px;
      border-radius: 40px;
      margin-bottom: 15px;
    }
    .zx-app-icon-star {
      margin-bottom: 15px;
      i {
        color: $yellow;
        font-size: 15px;
      }
    }
    .borderd {
      padding-bottom: 15px;
      border-bottom: 1px solid $gray-off;
    }
    .zx-app-testimonial-text {
      padding-top: 15px;
      h4 {
        font-size: 18px;
        font-family: $title-font;
        color: $black;
        font-weight: bold;
      }
    }
  }
}

// price
.zx-app-plans {
  max-width: 500px;
  margin: auto;
  padding: 30px;
  border-radius: 15px;
  box-shadow: $box-shadow;
  background: #fff;
  text-align: center;
  h4 {
    font-size: 30px;
    font-family: $title-font;
    color: $black;
    font-weight: bold;
    line-height: 1.3;
    text-align: center;
  }
  .zx-app-body {
    .zx-app-month {
      h5 {
        font-family: $title-font;
        font-size: 14px;
        font-weight: 400;
        color: $gray;
        margin-bottom: 0;
        span {
          color: $Pattens-Violet;
          vertical-align: 12px;
          font-weight: 600;
          padding-right: 3px;
        }
        code {
          font-family: $title-font;
          color: $Pattens-Violet;
          line-height: 1.333;
          font-size: 35px;
          font-weight: 700;
        }
      }
    }
    .zx-app-plans-buttons {
      padding: 10px;
      width: 190px;
      border-radius: 10px;
      background-color: $white;
      color: $black;
      font-size: 14px;
      font-family: $text-font;
      font-weight: bold;
      line-height: 1.867;
      border: 1px solid $black;
      transition: 0.3s ease-in-out;
      margin: 25px 0;
      &:hover {
        background-color: $Pattens-Violet;
        color: $white;
        border: 1px solid $Pattens-Violet;
      }
    }
    .zx-app-seo {
      background-color: $gray-light-off;
      padding: 20px 0;
      border-radius: 15px;
      border: 1px solid #eee;
      p {
        font-family: $text-font;
        font-size: 14px;
        line-height: 2;
        font-weight: 400;
        color: $gray;
        code {
          color: $Pattens-Violet;
          font-size: 17px;
          padding-right: 9px;
          vertical-align: middle;
        }
      }
    }
  }
}

//faq
.accordion {
  .accordion-item {
    margin-top: 25px;
    border: none;
    border-radius: 10px;
    .accordion-button {
      padding: 15px;
      margin-bottom: 0px;
      border: 1px solid $gray-off;
      border-radius: 10px !important;
      background-color: $white;
      font-size: 16px;
      font-family: $text-font;
      color: $black;
      font-weight: 500;
      line-height: 1.3;
      &:focus{
        box-shadow: none;
      }
      &::after {
        transform: scale(1.2) !important;
        font-family: "Font Awesome 6 Free";
        content: "\f061";
        font-size: 18px;
        font-weight: 700;
        display: flex;
        justify-content: center;
        text-align: center;
        color: #000;
        background-image: none;
      }
      &:not(.collapsed) {
        &::after {
          transform: scale(1.2) !important;
          font-family: "Font Awesome 6 Free";
          content: "\f063";
          font-size: 18px;
          font-weight: 700;
          display: flex;
          justify-content: center;
          text-align: center;
          color: $Pattens-Violet;
          background-image: none;
        }
      }
      .box {
        width: 50px;
        position: absolute;
        right: 0;
        border-left: 1px solid $gray-off;
      }
      &:not(.collapsed) {
        border: 1px solid $Pattens-Violet;
        .box {
          border-left: 1px solid $Pattens-Violet;
        }
      }
    }
    .accordion-collapse {
      border: 1px solid $Pattens-Violet;
      border-radius: 10px;
      .accordion-body {
        position: relative;
      }
    }
  }
}

.faq-top-them {
  position: absolute;
  top: -15px;
  left: 45px;
}

// theme
.footer-theme-1 {
  position: absolute;
  right: -40px;
  opacity: 0.5;
}

.footer-theme-3 {
  width: 30px;
  position: absolute;
  top: 30px;
  left: -17px;
  opacity: 0.5;
  z-index: 0;
}

.footer-theme-4 {
  position: absolute;
  right: 100px;
  bottom: -35px;
  opacity: 0.5;
  animation: topbottomrotate 5s infinite alternate;
}

.footer-theme-5 {
  width: 40px;
  position: absolute;
  bottom: -15px;
  left: 400px;
  opacity: 0.5;
}

.item {
  left: 50%;
  width: 100px;
}
@-webkit-keyframes topbottom {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(-10px);
  }
}
@keyframes topbottom {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(-10px);
  }
}
@-webkit-keyframes leftright {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-10px);
  }
}
@keyframes leftright {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-10px);
  }
}
@keyframes topbottomrotate {
  0% {
    transform: translateY(0) rotate(0deg);
  }
  100% {
    transform: translateY(-50px) rotate(30deg);
  }
}
