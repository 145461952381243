/* Contact page */
input {
    padding: 10px !important;
    &:not(:last-child) {
        margin-bottom: 30px;
    }
    box-shadow: none;
}

.form-control {
    border-radius: 12px;
    position: relative;
    z-index: 3;
    &:focus {
        border-color: $gray-off;
    }
}

textarea {
    margin-top: 30px;
    height: 100px;
}

.zx-button {
    margin-top: 30px;
    text-align: center;
}

.btn-small {
    padding: 10px 25px;
    background-color: $Pattens-Violet;
    color: $white;
    line-height: 20px;
    font-size: 16px;
    font-weight: 700;
    border: 1px solid $Pattens-Violet;
    border-radius: 20px;
    transition: 0.3s ease-in-out;
    &:hover {
        background-color: $white;
        color: $Pattens-Violet;
    }
}

.map_info {
    width: 100%;
    margin: 0;
    position: relative;
    iframe{
        height: 315px;
        border-radius: 15px;
    }
}
