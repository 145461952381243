/* Portfolio page */
.zx-portfolio {
    &:nth-child(1) {
        margin-bottom: 25px;
    }
    .zx-inner {
        .side-viwe {
            border-radius: 15px;
            position: relative;
            .content-overlay {
                background: $bg-dark;
                position: absolute;
                height: 100%;
                width: 100%;
                padding: 0;
                left: 0;
                top: 0;
                bottom: 0;
                right: 0;
                opacity: 0;
                transition: all 0.2s ease-in-out 0s;
                border-radius: 15px;
                margin: 0;
            }
            &:hover {
                .content-overlay {
                    opacity: 1;
                }
                .content-details {
                    top: 50%;
                    left: 50%;
                    opacity: 1;
                }
            }
            .content-details {
                position: absolute;
                text-align: center;
                padding-left: 1em;
                padding-right: 1em;
                width: 100%;
                top: 50%;
                left: 50%;
                opacity: 0;
                transform: translate(-50%, -50%);
                transition: all 0.3s ease-in-out 0s;
                a {
                    border: 1px solid $white;
                    color: $white;
                    padding: 10px;
                    margin: 3px;
                    border-radius: 10px;
                    i {
                        width: 20px;
                    }
                    &:hover {
                        background-color: $white;
                        color: $black;
                    }
                }
            }
            img {
                width: 100%;
                border-radius: 15px;
            }
            h4 {
                top: 10px;
                right: 0;
                position: absolute;
                font-size: 20px;
                span {
                    background-color: $blue;
                    color: $white;
                    font-size: 14px;
                    font-weight: bold;
                    padding: 6px 12px;
                    border-radius: 20px;
                }
            }
        }
    }
}

.overlay-bg-serch {
    display: none;
    position: fixed;
    z-index: 10;
    background: $bg-dark;
    top: 0;
    bottom: 0;
    left: 0;
    margin: auto;
    height: 100%;
    width: 100%;
    bottom: 0;
    cursor: zoom-out;
}

.zx-serch-box {
    visibility: hidden;
    position: fixed;
    z-index: 10;
    width: 100%;
    .close-button {
        position: absolute;
        top: -400px;
        right: 50px;
        z-index: 20;
        h3 {
            color: $red;
            font-size: 26px;
            font-weight: 700;
            cursor: pointer;
        }
    }
    .zoom-image {
        margin: 0;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        img {
            cursor: zoom-in;
            height: auto;
            display: block;
            box-sizing: border-box;
            margin: auto;
            border-radius: 15px;
            width: 100%;
            transform: scale(1.5);
        }
    }
}

.active-serch-box {
    visibility: visible;
    position: fixed;
    top: 50%;
    left: 0%;
    transform: translate(0px, 0px);
    z-index: 10;
    margin: auto;
    transition: transform 0.3s ease-out;
}

