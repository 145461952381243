@charset "UTF-8";

@import '~bootstrap/scss/bootstrap';

// core styling file
@import '~@fortawesome/fontawesome-free/scss/fontawesome';
// regular + Solid + Brands
@import '~@fortawesome/fontawesome-free/scss/regular';
@import '~@fortawesome/fontawesome-free/scss/solid';
@import '~@fortawesome/fontawesome-free/scss/brands';
//@import url('https://fonts.googleapis.com/css2?family=Rubik+Glitch');
/*---------------------------------------------------------------------------------
    Included CSS INDEX
-----------------------------------------------------------------------------------

01. Typography

02. Layout 
    - common
    - header
    - footer

03. Pages
    - home page
    - about page
    - blog page
    - contact page
    - services page
    - portfolio page

-----------------------------------------------------------------------------------*/

// Typography ( Import Typography )
@import "base/_typography";

// Utilities ( Import Utilities )
@import "utilities/_mixing";
@import "utilities/_variables";

// Layouts
@import "layout/_common";
@import "layout/_header";
@import "layout/_footer";

// Pages ( Import CSS for Pages )
@import "pages/_home-page";
@import "pages/_about-page";
@import "pages/_blog-page";
@import "pages/_contact-page";
@import "pages/_services-page";
@import "pages/_portfolio-page";

:root {
    --swiper-pagination-bottom: 0px;
}

.pointer{
    cursor: pointer;
}
.swiper-slide {
    padding-top: 10px;
    padding-bottom: 20px;
    img{
        max-height: 640px;
        max-width: 100%;
        width: auto;
    }
}
.swiper-slide-active {
    cursor: pointer;
}
@media screen and (max-width: 575px) {
    .swiper-slide img {
        max-height: 40vh;
    }
    .faq-image {
        max-height: 40vh;
        width: auto !important;
    }
    .hero-content {
        height: auto !important;
    }
    .hero-content-img {
        height: auto !important;
        margin: 0 !important;
    }
    .home-page-container {
        display: flex;
    }
    .home-page-row {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }
    .home-page-fade {
        display: flex;
        justify-content: space-around;
        flex-grow: 1;
        flex-direction: column;
    }
}