/* Home page */
.zx-bg-title {
    position: relative;

    p {
        margin-top: 20px;
        font-size: 16px;
        font-family: $text-font;
        font-weight: 500;
        text-align: center;
    }

    .zx-banner-button {
        margin: auto;
        display: flex;
        align-items: center;
        justify-content: center;

        a.button {
            padding: 8px 25px;
        }

        .zx-coling {
            padding-left: 15px;
            display: flex;
            align-items: center;

            a {
                padding-left: 6px;
                text-decoration: none;
                color: $black;
                font-size: 16px;
                font-weight: 700;
            }

            i {
                font-size: 20px;
                color: $Pattens-Violet;
            }
        }
    }
}

.banner-image {
    width: 100%;
    border-radius: 25px 25px 0 0;
    z-index: 1;
    position: relative;
}

.about-us {
    p {
        margin-bottom: 30px;
    }
}

.zx-project {
    border-top: 1px solid $gray-off;
    border-bottom: 1px solid $gray-off;
    padding: 10px 0;

    h4 {
        font-size: 40px;
        font-weight: 800;
    }

    p {
        margin-left: 5px;
        padding: 10px;
        display: flex;
        align-items: center;

        a {
            color: $gray;
            text-decoration: none;
            font-size: 16px;
            font-weight: 500;
        }
    }
}

.bg-market {
    background-color: $guyabano;
}

header {
    background-color: $white !important;
}

.zx-data {
    padding: 10px 0;

    p {
        color: #777;
        font-weight: 500;
        line-height: 2;

        &:not(:last-child) {
            margin-bottom: 10px;
        }

        i {
            color: $Pattens-Violet;
            padding-right: 7px;
        }
    }
}

.solutions {
    width: 100%;
}

.section-testimonial-us {
    position: relative;
}

.testimonial-image {
    width: 100%;
}

.testimonial-contain {
    .zx-thom {
        padding-bottom: 25px;
    }

    .testimonial-icons {
        i.fa-star {
            font-size: 20px;
            color: $yellow;
        }
    }

    p {
        padding-top: 25px;
        font-family: $text-font;
        font-size: 15px;
        font-weight: 400;
        line-height: 1.6;
        color: $gray;
        letter-spacing: 1px;
    }

    .testimonial-smith {
        margin-top: 35px;

        img {
            width: 65px;
            border-radius: 45px;
        }

        .smith-content {
            padding-left: 15px;

            h4 {
                padding-top: 10px;
                font-size: 18px;
                font-weight: 700;
                letter-spacing: 0px;
                line-height: 1;
            }

            p {
                padding-top: 1px;
                font-size: 14px;
                font-weight: 400;
                color: $gray;
            }
        }
    }
}

.pagipagination-testimonial {

    .swiper-pagination-bullets.swiper-pagination-horizontal,
    .swiper-pagination-custom,
    .swiper-pagination-fraction {
        right: 0 !important;
        left: auto;
        display: flex;
        justify-content: end;
    }
}

.zx-plans {
    border-radius: 20px;
    box-shadow: $box-shadow;
    background-color: $white;

    .basic {
        background-color: $black;
        border-top-left-radius: 20px;
        border-top-right-radius: 20px;

        p {
            margin: 0;
            padding: 15px;
            font-size: 16px;
            line-height: 2.8;
            font-weight: 700;
            color: $white;
            text-align: center;
        }
    }

    .zx-body {
        padding: 10px 30px;
        border-bottom-left-radius: 20px;
        border-bottom-right-radius: 20px;

        .zx-month {
            border-bottom: 1px solid $gray-off;
            text-align: center;

            h4 {
                margin-top: 10px;
                margin-bottom: 18px;
                font-family: $title-font;
                font-size: 15px;
                font-weight: 400;
                color: $gray;

                span {
                    padding-right: 3px;
                    color: $Pattens-Violet;
                    vertical-align: 12px;
                    font-weight: 600;
                }

                code {
                    font-family: $title-font;
                    color: $Pattens-Violet;
                    line-height: 1.333;
                    font-size: 35px;
                    font-weight: 700;
                }
            }
        }

        .zx-seo {
            margin-top: 20px;

            p {
                font-family: $text-font;
                font-size: 14px;
                line-height: 2.5;
                font-weight: 400;
                color: $gray;
                text-align: center;

                code {
                    padding-right: 9px;
                    color: $Pattens-Violet;
                    font-size: 17px;
                    vertical-align: middle;
                    display: inline-flex;
                    align-items: center;
                    justify-content: center;
                }
            }
        }
    }

    .close-plans {
        padding: 15px;
        border-bottom-left-radius: 20px;
        border-bottom-right-radius: 20px;
        border-top: 1px solid #eee;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;

        &:hover {
            i {
                margin-left: 10px;
            }
        }

        a {
            text-decoration: none;
            color: $gray;
            font-family: $text-font;
            font-size: 13px;
            font-weight: 700;
            line-height: 1.8;
        }

        i {
            @include ease3;
            margin-left: 5px;
            color: $gray;
        }
    }
}

.zx-presence {
    width: 100%;
    margin: 35px 0 0 0;
    border-radius: 20px;
}

.zx-brands {
    z-index: 1;

    .zx-approach {
        padding: 24px;
        display: flex;
        box-shadow: $box-shadow;
        border-radius: 20px;
        background-color: #fff;
        &:not(:last-child) {
            margin-bottom: 35px;
        }

        .zx-box {
            width: 90px;
            height: 90px;
            padding: 0 24px;
            margin-right: 24px;
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: $Pattens-Violet;
            color: $white;
            border-radius: 20px;
            font-size: 20px;
            font-weight: 700;
            position: relative;

            span {
                height: 25px;
                width: 25px;
                font-size: 14px;
                display: flex;
                align-items: center;
                justify-content: center;
                background-color: $black;
                color: $white;
                border-radius: 25px;
                position: absolute;
                top: -6px;
                right: -8px;
            }
        }

        .zx-tex {

            h4 {
                margin-bottom: 13px;
                font-family: $title-font;
                font-size: 18px;
                font-weight: 700;
            }

            p {
                line-height: 1.5;
                font-size: 14px;
                font-weight: 400;
            }
        }
    }
}

.digital-blog {
    &:not(:last-child) {
        margin-bottom: 24px;
        padding-bottom: 24px;
        border-bottom: 1px solid $gray-off;
    }

    h4 {
        margin-top: 10px;
        font-family: $title-font;
        font-size: 13px;
        font-weight: 400;
        color: $gray;

        code {
            line-height: 1.875;
            font-size: 16px;
            font-weight: 600;
            color: $Pattens-Violet;
        }
    }

    p,
    a {
        margin-bottom: 15px;
        font-size: 16px;
        line-height: 1.5;
        word-spacing: 2px;
        font-weight: 600;
        color: $black;
    }

    a {
        text-decoration: none;

        &:hover {
            h5 {
                i {
                    margin-left: 10px;
                }
            }
        }

        h5 {
            margin: 0;
            font-size: 14px;
            font-weight: 500;
            color: $gray;
            display: flex;
            align-items: center;

            i {
                @include ease3;
                margin-left: 5px;
            }
        }
    }
}

.blog-image {
    width: 100%;
    height: 350px;
    padding-bottom: 15px;
    border-radius: 30px;
}

.section-about {
    position: relative;
}

.section-about-us {
    position: relative;
}

.section-presence {
    position: relative;
}

// All Theme
.theme-1 {
    top: -9%;
    right: 5%;
    z-index: -1;
    position: absolute;
    opacity: .5;
}

.theme-2 {
    position: absolute;
    z-index: -1;
    left: 0;
    bottom: -60px;
    filter: blur(3px);
    animation: leftright 1s infinite  alternate;
    opacity: .5;
}

.theme-3 {
    position: absolute;
    left: 2%;
    width: 50px;
    z-index: -1;
    top: 320px;
    filter: blur(3px);
    opacity: .5;
}

.theme-4 {
    position: absolute;
    z-index: -1;
    bottom: 10%;
    right: 2%;
    opacity: .5;
}

.theme-5 {
    position: absolute;
    z-index: -1;
    top: 10%;
    right: 3%;
    filter: blur(3px);
    opacity: .5;
}

.theme-6 {
    position: absolute;
    right: 0;
    width: 300px;
    z-index: -1;
    bottom: -120px;
    opacity: .5;
}

.theme-7 {
    width: 80px;
    position: absolute;
    z-index: -1;
    bottom: 15%;
    animation: leftrightrotate 2s infinite alternate;
    opacity: .5;
}

.theme-8 {
    width: 80px;
    position: absolute;
    right: 0;
    z-index: -1;
    top: 30%;
    opacity: .5;
}

.theme-9 {
    width: 50px;
    position: absolute;
    z-index: -1;
    bottom: 0;
    right: 2%;
    opacity: .5;
}

.overlay {
    background-color: $guyabano;
    position: absolute;
    top: 0;
    z-index: -1;
    height: 800px;
    width: 100%;
    border-bottom-left-radius: 300px;
    border-bottom-right-radius: 300px;
}

// border-effect
.line-effect {
    position: absolute;
    top: 2%;
    right: 2%;
    z-index: -1;
    opacity: .5;
}
.section-banner {
    height: calc(100svh - 90px);
    margin-top: 80px;
    padding-bottom: 10px;
    display: flex;
}
.section-banner, .section-breadcrumb {
    position: relative;
    overflow: hidden;
    // background-color: $guyabano;
    .top_outline_01 {
        border-width: 2px;
        border-color: $yellow;
        border-style: solid;
        opacity: 0.3;
        position: absolute;
        left: -274px;
        top: -60px;
        width: 360px;
        height: 350px;
        border-radius: 200px;
        z-index: 0;
    }

    .top_outline_02 {
        border-width: 2px;
        border-color: $yellow;
        border-style: solid;
        opacity: 0.3;
        position: absolute;
        left: -300px;
        top: -150px;
        width: 400px;
        height: 400px;
        border-radius: 200px;
        z-index: 0;
        animation: topbottom 3s infinite  alternate;
    }

    .bottom_outline_01 {
        border-width: 2px;
        border-color: $yellow;
        border-style: solid;
        opacity: 0.3;
        position: absolute;
        right: -520px;
        top: 60px;
        width: 600px;
        height: 600px;
        border-radius: 500px;
        z-index: 0;
    }

    .bottom_outline_02 {
        border-width: 2px;
        border-color: $yellow;
        border-style: solid;
        opacity: 0.3;
        position: absolute;
        right: -500px;
        top: 130px;
        width: 600px;
        height: 600px;
        border-radius: 500px;
        z-index: 0;
        animation: topbottom 2s infinite alternate;
    }
}
@-webkit-keyframes topbottom {
    0% { transform: translateY(0); }
    100% { transform: translateY(-10px); }
}
@keyframes topbottom {
    0% { transform: translateY(0); }
    100% { transform: translateY(-10px); }
}
@-webkit-keyframes leftright {
    0% { transform: translateX(0); }
    100% { transform: translateX(-10px); }
}
@keyframes leftright {
    0% { transform: translateX(0); }
    100% { transform: translateX(-10px); }
}
@keyframes topbottomrotate {
    0% { 
        transform: translateY(0) rotate(0deg); 
    }
    100% { 
        transform: translateY(-50px) rotate(30deg); 
    }
}
@keyframes leftrightrotate {
    0% { transform: translateX(0) rotate(0deg); }
    100% { transform: translateX(-10px) rotate(30deg); }
}