
/* Blog page */
.zx-blog-serch-box {
    margin-bottom: 10px;
    .form-conrol {
        height: 50px;
        width: 100%;
        padding: 10px;
        margin: 0;
        border: 2px solid $gray-off;
        border-right: none;
        outline: none;
        border-radius: 10px 0 0 10px;
    }
    .blog-serch-button {
        background-color: $Pattens-Violet;
        color: $white;
        padding: 0px 15px;
        height: 50px;
        font-size: 15px;
        transition: 0.3s ease-in-out;
        border-radius: 0;
        border: 2px solid $Pattens-Violet;
        font-weight: 700;
        border-radius: 0 10px 10px 0;
        &:hover {
            background-color: $white;
            border: 2px solid $gray-off;
            color: $Pattens-Violet;
        }
    }
}

.zx-block-inner {
    padding: 0 0 25px 0;
    .zx-block {
        border-bottom: 1px solid $gray-off;
        padding: 15px 0;
        h3 {
            font-size: 22px;
            font-weight: 800;
            margin-bottom: 15px;
            margin-top: 5px;
        }
        a {
            text-decoration: none;
            color: $black;
            font-size: 16px;
            font-weight: 600;
            line-height: 28px;
        }
        h5 {
            margin-top: 10px;
            margin-bottom: 5px;
            font-size: 13px;
            font-weight: 400;
            color: #777;
        }
    }
}

.zx-caegories {
    margin-bottom: 25px;
    h3 {
        font-size: 22px;
        font-weight: 800;
        margin-bottom: 15px;
    }
}

input[type="checkbox"] + label {
    display: block;
    margin: 3px;
    cursor: pointer;
    padding: 3px;
    line-height: 28px;
    display: flex;
    align-items: center;
    color: #777;
}

input[type="checkbox"] {
    display: none;
}

input[type="checkbox"] + label:before {
    content: "\2714";
    border: 1px solid $gray;
    border-radius: 5px  ;
    display: inline-block;
    width: 20px;
    height: 20px;
    padding-left: 4px;
    margin-right: 10px;
    vertical-align: bottom;
    color: transparent;
    transition: 0.2s;
}

input[type="checkbox"]:checked + label:before {
    background-color: $Pattens-Violet;
    border-color: $Pattens-Violet;
    color: $white;
    line-height: 19px;
}

.pt-15 {
    padding-top: 15px !important;
}

.zx-blog-inner {
    margin-bottom: 30px;
    &:not(:last-child) {
        margin-bottom: 30px;
    }
    .zx-image {
        img {
            border-radius: 15px;
        }
    }
}