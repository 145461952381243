.silveridea-icon {
    color: #1D1E22;
    transition: color 2s ease-out;
    animation: enlight 10s infinite;
    font-size: 35px;
    padding-top: 0.2rem;
}
@keyframes enlight {
    10% {
      color: #5BD8F7;
    }
    20% {
      color: #52C1DD;
    }
    30% {
      color: #4BB2CC;
    }
    40% {
      color: #4199AF;
    }
    50% {
      color: #377F91;
    }
    60% {
      color: #2B6472;
    }
    70% {
      color: #FFD800;
    }
    80% {
      color: #FAFF7C;
    }
    90% {
      color: #808080;
    }    
    100% {
      color: #577C84;
    }
}
.silveridea-logo-text {
    margin-left: 10px;
    padding-top: 4px;
    font-size: 24px;
    //font-family: 'Rubik Glitch', cursive;
    font-weight: bold;
    color: #4bb2cc; //#626468;
}